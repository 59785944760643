export type ColorValue = '50' | '100' | '200' | '300' | '400' | '500' | '600' | '700' | '800' | '900';
export type ColorSchemes =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'neutral'
  | 'danger'
  | 'warning'
  | 'success'
  | 'primaryAlpha'
  | 'secondaryAlpha'
  | 'dangerAlpha'
  | 'primaryAlphaInverse'
  | 'secondaryAlphaInverse'
  | 'blackAlpha'
  | 'whiteAlpha'
  | 'dangerAlphaInverse';
export type ColorValueMap = Record<ColorValue, string>;

export type CoreColors = Record<ColorSchemes, ColorValueMap>;

export const semanticTypographyTokens = [
  'text.default',
  'text.subtle',
  'text.info',
  'text.brand',
  'text.brand.hover',
  'text.interactive',
  'text.interactive.hover',
  'text.success',
  'text.warning',
  'text.danger',
  'text.placeholder',
  'text.accent',
  'text.accentSubtle',
  'text.onContrast',
  'text.subtleOnContrast',
  'text.interactiveOnContrast',
  'text.interactiveOnContrast.hover',
  'text.brandOnContrast',
  'text.brandOnContrast.hover',
  'text.placeholderOnContrast',
  'text.dangerOnContrast',
] as const;
export type SemanticTypographyToken = (typeof semanticTypographyTokens)[number];

export const semanticBackgroundTokens = [
  'background.default',
  'background.subtle',
  'background.lessSubtle',
  'background.primary',
  'background.primary.hover',
  'background.primary.pressed',
  'background.secondary',
  'background.secondary.hover',
  'background.secondary.pressed',
  'background.info',
  'background.brand',
  'background.success',
  'background.warning',
  'background.danger',
  'background.destructive',
  'background.destructive.hover',
  'background.destructive.pressed',
  'background.accent',
  'background.accentSubtle',
  'background.note',
  'background.note.hover',
  'background.alpha',
  'background.alpha.hover',
  'background.alpha.pressed',
  'background.primaryAlpha',
  'background.primaryAlpha.hover',
  'background.primaryAlpha.pressed',
  'background.secondaryAlpha',
  'background.secondaryAlpha.hover',
  'background.secondaryAlpha.pressed',
  'background.destructiveAlpha',
  'background.destructiveAlpha.hover',
  'background.destructiveAlpha.pressed',
  'background.overlay',
  'background.backdrop',
  'background.contrast',
  'background.subtleContrast',
  'background.moreSubtleContrast',
  'background.mostSubtleContrast',
  'background.primaryContrast',
  'background.primaryContrast.hover',
  'background.primaryContrast.pressed',
  'background.secondaryContrast',
  'background.secondaryContrast.hover',
  'background.secondaryContrast.pressed',
  'background.successContrast',
  'background.warningContrast',
  'background.dangerContrast',
  'background.destructiveContrast',
  'background.destructiveContrast.hover',
  'background.destructiveContrast.pressed',
  'background.alphaContrast',
  'background.alphaContrast.hover',
  'background.alphaContrast.pressed',
  'background.primaryAlphaContrast',
  'background.primaryAlphaContrast.hover',
  'background.primaryAlphaContrast.pressed',
  'background.secondaryAlphaContrast',
  'background.secondaryAlphaContrast.hover',
  'background.secondaryAlphaContrast.pressed',
  'background.destructiveAlphaContrast',
  'background.destructiveAlphaContrast.hover',
  'background.destructiveAlphaContrast.pressed',
] as const;
export type SemanticBackgroundColorToken = (typeof semanticBackgroundTokens)[number];

export const semanticBorderTokens = [
  'divider.default',
  'border.default',
  'border.subtle',
  'border.strong',
  'border.focus',
  'border.brand',
  'border.info',
  'border.success',
  'border.warning',
  'border.danger',
  'border.accent',
  'border.accentSubtle',
  'border.contrast',
  'border.subtleContrast',
  'border.focusContrast',
  'border.strongContrast',
  'border.dangerContrast',
] as const;
export type SemanticBorderColorToken = (typeof semanticBorderTokens)[number];

export type SemanticColorToken = SemanticTypographyToken | SemanticBackgroundColorToken | SemanticBorderColorToken;
