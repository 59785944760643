import React, { ForwardedRef, forwardRef, useMemo } from 'react';
import { IconSize, IconSizeMap } from './icon.types';
import { IconMap, IconName } from '../../icons';
import { Box, BoxProps } from '@chakra-ui/react';
import { TypographyVariant } from '../../shared/types/typography';
import { SemanticTypographyToken } from '../../theme/tokens';

export interface BaseIconProps {
  name: IconName;
  size?: IconSize;
  variant?: TypographyVariant;
  color?: string | SemanticTypographyToken;
}

export type IconProps = BaseIconProps & BoxProps;

export const Icon = forwardRef(
  ({ name, size = 'lg', color, variant, ...rest }: IconProps, ref: ForwardedRef<any>): JSX.Element => {
    const MappedIcon = IconMap[name];
    const d = IconSizeMap[size] + 'px';

    const iconColor = useMemo(() => {
      return variant ? `text.${variant}` : color;
    }, [variant, color]);

    return (
      <Box className="Icon__Wrapper" color={iconColor} display="inline-block" {...rest}>
        <MappedIcon className="Icon" ref={ref} width={d} height={d} color="currentColor" />
      </Box>
    );
  },
);

Icon.displayName = 'Icon';
