import React from 'react';
import { TypographyVariant } from 'shared/types';
import { Icon, IconProps } from '../../Icon/icon.component';

export interface LinkIconProps extends Omit<IconProps, 'position'> {
  position?: 'start' | 'end';
  variant?: TypographyVariant;
}

export const LinkIcon = ({ position, variant, ...rest }: LinkIconProps): JSX.Element => {
  return <Icon variant={variant || 'interactive'} {...rest} />;
};
